.photoBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  width: calc(100% - 40px); /* This will give a 20px margin between each photo block */
  margin: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.photoBlock .photo {
  width: 350px;
  height: 350px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  margin: 20px;
  padding-top: 0;
}

.photoBlock .photo:hover {
  transform: scale(1.1);
}

.photoBlockClicked {
  justify-content: center;
  margin: 0;
}


.photoBlockClicked .photo {
  display: none;
  
}

.photoBlockClicked .photoClicked {
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 85vh;
  background: rgba(0, 0, 0, 0.8);
  object-fit: contain;
  padding-top: 0;
}
/*maybe add transition here */


.photoBlockClicked h3 {
  z-index: 2;
  font-size:xx-large;
  font-family: 'Courier New', Courier, monospace;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  bottom: 0;
  width: 80%;
  background: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: -4px;
  min-height: 15vh;
}

@media screen and (max-width: 768px) {
  .photoBlock {
    align-content: center;
  }

  .photoBlock .photo {
    min-width: 70%;
    min-height: 70%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
    margin: auto;
    margin-bottom: 30px;
    text-align: -webkit-center;
  }

  .photoBlockClicked .photoClicked {
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    background: rgba(0, 0, 0, 0.8);
    object-fit: contain;
    padding-top: 20px;
  }

  .photoBlockClicked h3 {
    margin-bottom: 20px;
    
  }
}