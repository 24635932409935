.App {
	text-align: center;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  background-color: #3e3a36a2;
  min-height: 100vh;
}

h2 {
  margin-top: 0;
  padding: 10px;
}

p {
  margin-top: 0;
  padding: 10px;
  font-size: 14px;
}

h1 {
  margin-top: 0;
  padding: 15px;

}

@media screen and (max-width: 768px) {
 .content {
  top: 0.1px;
 }
 .App {
  background-size :contain;
  background-color: black;
  /* background-color: linear-gradient(black, black, rgba(20, 83, 43, 0.729)); */
  /* background: linear-gradient(white 0%, red 10%, red 90%, white 100%); */
  background-position-y: top;
 }
}