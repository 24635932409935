.about {
  width: 80%;
  margin-left: 10%;
  font-size: large;
  line-height: 150%;
}

a {
  color: orangered;
}

.bandMember {
  color: cadetblue;
}

img {
  padding-top: 10px;
}